export function getCurrencySymbol(currency) {

    if (currency) {
        switch (currency.toLowerCase()) {
            case 'usd':
                return '$';
            case 'eur':
                return '€';
            case 'gbp':
                return '£';
            case 'jpy':
                return '¥';
            case 'rub':
                return '₽';
            case 'rsd':
                return 'RSD';
            case 'km':
                return 'KM';
            default:
                return 'RSD';
        }
    }

    else {
        const runtimeConfig = useRuntimeConfig()
        const siteCurrency = runtimeConfig.public.siteCurrency

        switch (siteCurrency) {
            case 'usd':
                return '$';
            case 'eur':
                return '€';
            case 'gbp':
                return '£';
            case 'jpy':
                return '¥';
            case 'rub':
                return '₽';
            case 'rsd':
                return 'RSD';
            case 'km':
                return 'KM';
            default:
                return 'RSD';
        }
    }


}